import { type FC } from "react";

export const StarFill: FC = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.0127 4.21162C6.38725 3.27788 6.57452 2.81102 6.87877 2.74631C6.95877 2.7293 7.04146 2.7293 7.12146 2.74631C7.42571 2.81102 7.61298 3.27788 7.98753 4.21162C8.20052 4.74261 8.30702 5.00811 8.50629 5.18869C8.56219 5.23934 8.62286 5.28445 8.68747 5.32339C8.91779 5.46221 9.20531 5.48796 9.78035 5.53946C10.7538 5.62664 11.2405 5.67023 11.3891 5.94775C11.4199 6.00522 11.4409 6.06745 11.4511 6.13185C11.5003 6.44278 11.1425 6.76832 10.4269 7.41939L10.2282 7.60019C9.89361 7.90457 9.72633 8.05677 9.62957 8.2467C9.57152 8.36064 9.53261 8.48334 9.51437 8.6099C9.48397 8.82089 9.53296 9.04167 9.63093 9.48325L9.66594 9.64102C9.84163 10.4329 9.92948 10.8289 9.81982 11.0235C9.72131 11.1983 9.53987 11.3102 9.33944 11.3198C9.1163 11.3304 8.80189 11.0742 8.17307 10.5619C7.75878 10.2243 7.55164 10.0555 7.32168 9.98954C7.11154 9.92928 6.88869 9.92928 6.67855 9.98954C6.44859 10.0555 6.24145 10.2243 5.82716 10.5619C5.19834 11.0742 4.88393 11.3304 4.66079 11.3198C4.46036 11.3102 4.27892 11.1983 4.18041 11.0235C4.07075 10.8289 4.1586 10.4329 4.33429 9.64102L4.3693 9.48325C4.46727 9.04167 4.51626 8.82089 4.48586 8.6099C4.46762 8.48334 4.42871 8.36064 4.37066 8.2467C4.2739 8.05677 4.10662 7.90457 3.77205 7.60019L3.57333 7.41939C2.8577 6.76832 2.49989 6.44278 2.54916 6.13185C2.55937 6.06745 2.5803 6.00522 2.61108 5.94775C2.75971 5.67023 3.24644 5.62664 4.21988 5.53946C4.79492 5.48796 5.08244 5.46221 5.31276 5.32339C5.37737 5.28445 5.43804 5.23934 5.49394 5.18869C5.69321 5.00811 5.79971 4.74261 6.0127 4.21162Z"
      fill="#1AACFF"
      stroke="#1AACFF"
      strokeWidth="1.16667"
    />
  </svg>
);
