import { type FC } from "react";

export const Bell: FC = () => (
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.433 16.603C14.0879 10.709 19.0698 6.25 25 6.25V6.25C30.9302 6.25 35.9121 10.709 36.567 16.603L37.0916 21.3242C37.0983 21.3844 37.1016 21.4145 37.105 21.4443C37.3737 23.7853 38.1357 26.0428 39.3407 28.0677C39.3561 28.0934 39.3716 28.1194 39.4028 28.1713L40.607 30.1784C41.6999 31.9998 42.2463 32.9104 42.1283 33.658C42.0498 34.1555 41.7938 34.6077 41.4076 34.9309C40.8273 35.4167 39.7652 35.4167 37.6412 35.4167H12.3588C10.2348 35.4167 9.17273 35.4167 8.59237 34.9309C8.2062 34.6077 7.95018 34.1555 7.87169 33.658C7.75373 32.9104 8.30014 31.9998 9.39296 30.1784L10.5972 28.1713C10.6284 28.1194 10.6439 28.0934 10.6593 28.0677C11.8643 26.0428 12.6263 23.7853 12.895 21.4443C12.8984 21.4145 12.9017 21.3844 12.9084 21.3242L13.433 16.603Z"
      stroke="#1AACFF"
      strokeWidth="4.16667"
    />
    <path
      d="M16.6666 35.4167C16.6666 36.511 16.8821 37.5946 17.3009 38.6057C17.7197 39.6167 18.3335 40.5354 19.1074 41.3092C19.8812 42.083 20.7998 42.6969 21.8109 43.1157C22.8219 43.5345 23.9056 43.75 24.9999 43.75C26.0943 43.75 27.1779 43.5345 28.1889 43.1157C29.2 42.6969 30.1187 42.083 30.8925 41.3092C31.6663 40.5354 32.2801 39.6167 32.6989 38.6057C33.1177 37.5946 33.3333 36.511 33.3333 35.4167"
      stroke="#1AACFF"
      strokeWidth="4.16667"
      strokeLinecap="round"
    />
  </svg>
);
