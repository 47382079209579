import { type FC } from "react";

export const Star: FC = () => (
  <svg
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.34576 3.86608C6.80461 2.72217 7.03404 2.15021 7.42856 2.15021C7.82309 2.15021 8.05251 2.72217 8.51136 3.86608L8.53273 3.91935C8.79196 4.56561 8.92157 4.88873 9.18574 5.08514C9.4499 5.28154 9.79667 5.31259 10.4902 5.3747L10.6156 5.38593C11.7506 5.48759 12.3182 5.53841 12.4396 5.89949C12.561 6.26056 12.1396 6.64401 11.2966 7.41091L11.0153 7.66686C10.5886 8.05508 10.3752 8.24919 10.2758 8.5036C10.2572 8.55106 10.2418 8.59968 10.2296 8.64915C10.1643 8.91437 10.2267 9.19596 10.3517 9.75915L10.3906 9.93446C10.6202 10.9695 10.7351 11.487 10.5346 11.7102C10.4597 11.7936 10.3623 11.8537 10.2541 11.8832C9.96468 11.9622 9.55374 11.6273 8.73185 10.9576C8.19216 10.5178 7.92232 10.298 7.61251 10.2485C7.49065 10.229 7.36647 10.229 7.24461 10.2485C6.9348 10.298 6.66496 10.5178 6.12528 10.9576C5.30339 11.6273 4.89244 11.9622 4.60299 11.8832C4.49483 11.8537 4.39747 11.7936 4.32255 11.7102C4.12207 11.487 4.2369 10.9695 4.46654 9.93446L4.50543 9.75915C4.63039 9.19596 4.69287 8.91437 4.6275 8.64915C4.61531 8.59968 4.59989 8.55106 4.58134 8.5036C4.48189 8.24919 4.26854 8.05508 3.84182 7.66686L3.56049 7.41091C2.71755 6.64401 2.29608 6.26056 2.41752 5.89949C2.53896 5.53841 3.10648 5.48759 4.24154 5.38593L4.36692 5.3747C5.06046 5.31259 5.40722 5.28154 5.67139 5.08514C5.93555 4.88873 6.06516 4.56561 6.32439 3.91935L6.34576 3.86608Z"
      stroke="#1AACFF"
      strokeWidth="1.16667"
    />
  </svg>
);
